import antdIT from "antd/lib/locale-provider/it_IT";
import itMessages from "../locales/it_IT.json";

const ItLang = {
  messages: {
    ...itMessages
  },
  antd: antdIT,
  locale: 'it-IT',
};
export default ItLang;
