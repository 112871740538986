import React from "react";
import { injectIntl } from "react-intl";
class Blank extends React.Component {
  render() {
    return (
      <div className="gx-page-error-container">
        <div className="gx-page-error-content"></div>
      </div>
    );
  }
}
export default injectIntl(Blank);
