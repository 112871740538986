import React from "react";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { Route, Switch } from "react-router-dom";
import "assets/vendors/style";
import "styles/wieldy.less";
import configureStore, { history } from "./appRedux/store";
import "./firebase/firebase";
import App from "./containers/App/App";
import Context from "./Context";
import api from "./api/rest";
import ocpp from "./api/ocpp";
import userActions from "./appRedux/actions/User";

const store = configureStore();

const cx = {
  api,
  ocpp,
};

const usetInit = () =>
  new Promise(() => {
    store.dispatch(userActions.authCheck());
  });

usetInit();

const ReactApp = () => (
  <Context.Provider value={cx}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Switch>
          <Route path="/" component={App} />
        </Switch>
      </ConnectedRouter>
    </Provider>
  </Context.Provider>
);

export default ReactApp;
