import actions from "../actions/User";

const initState = {
  show: {},
  raw: {},

  id: null,
  userid: null,
  participant: null,
  isPending: false,
  isAuthorized: false,
  isAdmin: false,
  isGuest: false,
  isParticipant: false,
  isError: false,
  errorMessage: "",

  access: {},
};

export default function authReducer(state = initState, action) {
  const normalizeRaw = (raw) => {
    let result = {};
    if (raw.name.first || raw.name.last) {
      let firstName = raw.name.first || "";
      let lastName = raw.name.last || "";
      result.name = `${raw.name.first || ""} ${raw.name.last || ""}`;

      result.literal = `${firstName[0] ? firstName[0].toUpperCase() : ""}${
        lastName[0] ? lastName[0].toUpperCase() : ""
      }`;

      result.hello = `${firstName} ${lastName}`;
    } else {
      result.name = raw.profile.name || raw.profile.username;
      result.hello = result.name;
      result.literal = result.name[0].toUpperCase();
    }

    result.picture = raw.profile.picture || false;
    return result;
  };

  switch (action.type) {
    case actions.AUTH_BEGIN:
      return {
        ...state,
        isPending: true,
      };
    case actions.AUTH_SUCCESS:
      return {
        ...state,
        isAuthorized: true,
        isPending: false,
        isError: false,
        errorMessage: "",
        raw: action.authResult,
        show: normalizeRaw(action.authResult),
        id: action.authResult.id,
        userid: action.authResult.userid,
        account: action.authResult.account,
        participant: action.authResult.participant,
        isAdmin: action.authResult.admin ? true : false,
        isGuest: action.authResult.guest ? true : false,
        isParticipant: action.authResult.participant ? true : false,
        access: action.accessMap,
        types: action.typesMap,
        interface: action.authResult.interface.code,
        connectionLink: `${window.appConfig.wsDomain}/ocpp/${!action.authResult.account.code ? '' : action.authResult.account.code}`,
      };
    case actions.AUTH_ERROR:
      return {
        ...initState,
        isError: true,
        errorMessage: action.errorMessage,
      };
    case actions.LOGOUT:
      return initState;
    default:
      return state;
  }
}
