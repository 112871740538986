import React from "react";
import { Button, Form, Input, Alert, Checkbox } from "antd";
import { Link } from "react-router-dom";
import CircularProgress from "../components/CircularProgress";
import { injectIntl } from "react-intl";
import Context from "../Context";
import credentialToken from "./../api/credentials";
import token from "./../api/token";
import { Helmet } from "react-helmet";

const FormItem = Form.Item;
const authImmediate = false;

class SignUp extends React.Component {
  static contextType = Context;
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      success: false,
      pending: false,
    };
  }
  m = (key) => {
    const { intl } = this.props;
    return intl.formatMessage({ id: "signup." + key });
  };
  save = async (data) => {
    this.setState({ pending: true, error: false, success: false });
    let result = await this.context.api.q(
      "/sign/up",
      {
        username: data.login,
        email: data.email,
        password: data.password,
      },
      { exact: true },
      credentialToken.getAccess()
    );

    if (result.id) {
      if (authImmediate) {
        let authResult = await this.context.api.q(
          "/oauth2/token",
          {
            grant_type: "password",
            username: data.login,
            password: data.password,
          },
          { exact: true },
          false,
          true
        );
        if (authResult.access_token) {
          token.set(
            authResult.access_token,
            null,
            authResult.expires_in,
            authResult.session
          );
        }
        document.location.href = "/";
      } else {
        this.setState({ success: true, pending: false });
      }
    } else {
      if (result.hasOwnProperty("error")) {
        this.setState({ error: result.error.message });
      }
      this.setState({ pending: false });
    }
  };
  render() {
    const { pending, error, success } = this.state;
    const mConfirmInvalid = this.m("password.confirm.invalid");

    return (
      <div className="gx-app-login-wrap">
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg">
                <img src={"/assets/images/sbg.png"} alt="OCPP CS" />
              </div>
              <div className="gx-app-logo-wid">
                <Helmet>
                  <title>{this.m("signup")}</title>
                </Helmet>
                <h1>{this.m("signup")}</h1>
                <p>{this.m("text")}</p>
              </div>
              <div className="gx-app-logo">
                <img alt="example" src="/assets/images/logo.png" />
              </div>
            </div>

            <div className="gx-app-login-content">
              {success ? (
                <div>
                  <Alert message={this.m("success")} type="success" />
                  <Link to="/">{this.m("mainpage")}</Link>
                </div>
              ) : (
                <Form
                  name="basic"
                  onFinish={this.save}
                  onFinishFailed={null}
                  className="gx-signin-form gx-form-row0"
                >
                  <FormItem
                    rules={[
                      { required: true, message: this.m("username.invalid") },
                    ]}
                    name="login"
                  >
                    <Input placeholder={this.m("username")} />
                  </FormItem>

                  <FormItem
                    name="email"
                    rules={[
                      {
                        required: true,
                        type: "email",
                        message: this.m("email.invalid"),
                      },
                    ]}
                  >
                    <Input placeholder={this.m("email")} />
                  </FormItem>
                  <FormItem
                    name="password"
                    rules={[
                      { required: true, message: this.m("password.invalid") },
                    ]}
                  >
                    <Input.Password placeholder={this.m("password")} />
                  </FormItem>
                  <FormItem
                    name="passwordconfirm"
                    dependencies={["password"]}
                    rules={[
                      {
                        required: true,
                        message: this.m("password.confirm.empty"),
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error(mConfirmInvalid));
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      suffix={null}
                      placeholder={this.m("password.confirm")}
                    />
                  </FormItem>
                  <Form.Item
                    name="iaccept"
                    valuePropName="checked"
                    rules={[
                      {
                        validator: (_, value) =>
                          value
                            ? Promise.resolve()
                            : Promise.reject(
                                new Error(this.m("iaccept.invalid"))
                              ),
                      },
                    ]}
                  >
                    <Checkbox>
                      {this.m("iaccept")}{" "}
                      <Link to="/terms">{this.m("terms")}</Link>
                    </Checkbox>
                  </Form.Item>
                  {error && <Alert message={error} type="error" />}

                  <FormItem>
                    <Button
                      type="primary"
                      className="gx-mb-0"
                      htmlType="submit"
                    >
                      {this.m("signup")}
                    </Button>
                    <span>{this.m("or")}</span>{" "}
                    <Link to="/signin">{this.m("signin")}</Link>
                  </FormItem>
                </Form>
              )}
            </div>

            {pending && (
              <div className="gx-loader-view">
                <CircularProgress />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(SignUp);
