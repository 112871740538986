import React from "react";
import {
  CloudSyncOutlined,
  DatabaseOutlined,
  UsergroupAddOutlined,
  UserOutlined,
  BuildOutlined,
  ExportOutlined,
  ContainerOutlined,
  PartitionOutlined,
  AppstoreOutlined,
  FallOutlined,
  NodeExpandOutlined,
  ProfileOutlined,
  CreditCardOutlined,
  AuditOutlined,
  CarOutlined,
  TeamOutlined,
  IdcardOutlined,
  GlobalOutlined,
  EuroCircleOutlined,
} from "@ant-design/icons";

const horizontalMenu = {
  "/admin": {
    "sidebar.admin.journals": {
      "admin.apilog.apilog": {
        label: "admin.apilog.apilog",
        url: "/admin/apilog",
        icon: <CloudSyncOutlined />,
      },
      "admin.dblog.dblog": {
        label: "admin.dblog.dblog",
        url: "/admin/dblog",
        icon: <DatabaseOutlined />,
      },
    },
    "sidebar.admin.groups": {
      "admin.groups.groups": {
        label: "admin.groups.groups",
        url: "/admin/groups",
        icon: <UsergroupAddOutlined />,
      },
      "admin.users.users": {
        label: "admin.users.users",
        url: "/admin/users",
        icon: <UserOutlined />,
      },
    },
    "sidebar.admin.tools": {
      "admin.registry.registry": {
        label: "admin.registry.registry",
        url: "/admin/registry",
        icon: <BuildOutlined />,
      },
      "admin.console.console": {
        label: "admin.console.console",
        url: "/admin/console",
        icon: <ExportOutlined />,
      },
      "admin.resource.resource": {
        label: "admin.resource.resource",
        url: "/admin/resource",
        icon: <ContainerOutlined />,
      },
    },
    "sidebar.admin.settings": {
      "admin.workflow.workflow": {
        label: "admin.workflow.workflow",
        url: "/admin/workflow",
        icon: <PartitionOutlined />,
      },
      "admin.reference.reference": {
        label: "admin.reference.reference",
        url: "/admin/reference",
        icon: <ProfileOutlined />,
      },
    },
  },
  "/ocpp": {
    "sidebar.ocpp.ocpp": {
      "ocpp.stationlist.stationlist": {
        label: "ocpp.stationlist.stationlist",
        url: "/ocpp",
        icon: <AppstoreOutlined />,
      },
      "ocpp.commands.commands": {
        label: "ocpp.commands.commands",
        url: "/ocpp/command",
        icon: <FallOutlined />,
      },
    },
  },
};

const sidebarMenu = {
  "/crm": {
    "sidebar.crm.stations": {
      label: "sidebar.crm.stations",
      url: "/crm/stations",
      icon: <DatabaseOutlined />,
    },
    "sidebar.crm.transactions": {
      label: "sidebar.crm.transactions",
      url: "/crm/transactions",
      icon: <NodeExpandOutlined />,
    },
    "sidebar.crm.cards": {
      label: "sidebar.crm.cards",
      url: "/crm/cards",
      icon: <CreditCardOutlined />,
    },
    "sidebar.crm.invoice": {
      label: "sidebar.crm.invoice",
      url: "/crm/invoice",
      icon: <AuditOutlined />,
    },
	"sidebar.crm.client": {
		label: "sidebar.crm.client",
		url: "/crm/client",
		icon: <TeamOutlined />,
	  },
	  "sidebar.crm.account": {
		label: "sidebar.crm.account",
		url: "/crm/account",
		icon: <IdcardOutlined />,
	  },
	  "sidebar.crm.network": {
		label: "sidebar.crm.network",
		url: "/crm/network",
		icon: <GlobalOutlined />,
	  },
	  "sidebar.crm.tariff": {
		label: "sidebar.crm.tariff",
		url: "/crm/tariff",
		icon: <EuroCircleOutlined />,
	  },
  },

  "/driver": {
    "sidebar.crm.stations": {
      label: "sidebar.driver.stations",
      url: "/driver",
      icon: <div className="icon icon-map-simple"></div>,
    },
    "sidebar.crm.cars": {
      label: "sidebar.driver.cars",
      url: "/driver/cars",
      icon: <CarOutlined />,
    },
    "sidebar.crm.cards": {
      label: "sidebar.driver.cards",
      url: "/driver/cards",
      icon: <CreditCardOutlined />,
    },
	
  },

  "/ocpp": {
    "ocpp.stationlist.stationlist": {
      label: "ocpp.stationlist.stationlist",
      url: "/ocpp",
      icon: <AppstoreOutlined />,
    },
    "ocpp.commands.commands": {
      label: "ocpp.commands.commands",
      url: "/ocpp/command",
      icon: <FallOutlined />,
    },
  },

  "/admin": {
    "admin.apilog.apilog": {
      label: "admin.apilog.apilog",
      url: "/admin/apilog",
      icon: <CloudSyncOutlined />,
    },
    "admin.dblog.dblog": {
      label: "admin.dblog.dblog",
      url: "/admin/dblog",
      icon: <DatabaseOutlined />,
    },
    "admin.groups.groups": {
      label: "admin.groups.groups",
      url: "/admin/groups",
      icon: <UsergroupAddOutlined />,
    },
    "admin.users.users": {
      label: "admin.users.users",
      url: "/admin/users",
      icon: <UserOutlined />,
    },
    "admin.registry.registry": {
      label: "admin.registry.registry",
      url: "/admin/registry",
      icon: <BuildOutlined />,
    },
    "admin.console.console": {
      label: "admin.console.console",
      url: "/admin/console",
      icon: <ExportOutlined />,
    },
    "admin.resource.resource": {
      label: "admin.resource.resource",
      url: "/admin/resource",
      icon: <ContainerOutlined />,
    },
    "admin.workflow.workflow": {
      label: "admin.workflow.workflow",
      url: "/admin/workflow",
      icon: <PartitionOutlined />,
    },
    "admin.reference.reference": {
      label: "admin.reference.reference",
      url: "/admin/reference",
      icon: <ProfileOutlined />,
    },
	
  },
};

export { horizontalMenu, sidebarMenu };
