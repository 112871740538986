import React from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import actionsUser from "appRedux/actions/User";
import { setThemeType } from "appRedux/actions/Setting";
import {
  THEME_TYPE_DARK,
  THEME_TYPE_SEMI_DARK,
} from "../../constants/ThemeSetting";
import { CheckOutlined } from "@ant-design/icons";

const UserMenu = () => {
  const dispatch = useDispatch();
  const { themeType } = useSelector(({ settings }) => settings);
  const intl = useIntl();
  const m = (key) => {
    return intl.formatMessage({ id: "userprofile." + key });
  };
  const config = window.appConfig || {};
  const dark = themeType === "THEME_TYPE_DARK";
  return (
    <ul className="gx-user-popover">
      {config.confCrm && (
        <li>
          <Link className="gx-link" to="/crm/profile">
            {m("profile")}
          </Link>
        </li>
      )}
      <li
        onClick={() =>
          dispatch(setThemeType(dark ? THEME_TYPE_SEMI_DARK : THEME_TYPE_DARK))
        }
      >
        <span className="gx-link">
          {dark && <CheckOutlined />} {m("dark")}
        </span>
      </li>
      <li onClick={() => dispatch(actionsUser.logout())}>
        <span className="gx-link">{m("logout")}</span>
      </li>
    </ul>
  );
};
export default UserMenu;
