import rest from "./rest";
import commands from "./ocpp/commands";
const ocpp = {
  commands,
  q: (path, body = {}) => {
    const config = window.appConfig || {};
    const url = `${config.ocppApiDomain}${config.ocppApiPath}${path}`;
    return rest.q(
      path,
      body,
      { exact: true },
      false,
      config.confAuthorize ? false : true,
      url
    );
  },
  getArray: (result, key, seekKey) => {
    if (Array.isArray(result[key])) {
      return result[key];
    } else {
      if (result?.[key]?.[seekKey]) {
        return [result[key]];
      }
    }
    return [];
  },
};

export default ocpp;
