import enLang from "./entries/en-US";
import ruLang from "./entries/ru-RU";
import deLang from "./entries/de-DE";
import frLang from "./entries/fr-FR";
import itLang from "./entries/it-IT";
import nlLang from "./entries/nl-NL";

const AppLocale = {
  en: enLang,
  ru: ruLang,
  de: deLang,
  fr: frLang,
  it: itLang,
  nl: nlLang
};

export default AppLocale;
