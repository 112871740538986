import {
  BellOutlined,
  CarOutlined,
  DatabaseOutlined,
  ToolOutlined,
} from "@ant-design/icons";
import React from "react";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {Link, Route} from "react-router-dom";
import Flex from "../../util/Flex";
import {setThemeType} from "../../appRedux/actions/Setting";
import {changeModePath} from "../../appRedux/actions/Common";
import {
  THEME_TYPE_DARK,
  THEME_TYPE_SEMI_DARK,
} from "../../constants/ThemeSetting";

const AppsNavigation = () => {
  const config = window.appConfig || {};
  const user = useSelector(({user}) => user);
  const intl = useIntl();

  const dispatch = useDispatch();
  const {themeType} = useSelector(({settings}) => settings);
  const dark = themeType === "THEME_TYPE_DARK";

  const m = (key) => {
    return intl.formatMessage({id: "icons." + key});
  };

  if (!config.confCrm) return null;
  return (
    <ul className="gx-app-nav" style={{justifyContent: "space-between"}}>
      <Flex>
        {user.account && (
          <li
            className="gx-app-balance gx-mb-4">{user.account.balance ? user.account.balance : 0} {user.account.currency_code}
          </li>
        )}
        </Flex>
        <Flex>
        <li>

          <Link className={dark ? "" : "gx-icon-white"}
                to={user.interface === "driver" ? "/driver/notice/" : "/crm/notice/"} title={m("notifications")}>
            <BellOutlined/>
          </Link>

        </li>

        <li>
          <div
            title={m("dark")}
            className="gx-app-linkicon"
            onClick={() =>
              dispatch(
                setThemeType(dark ? THEME_TYPE_SEMI_DARK : THEME_TYPE_DARK)
              )
            }
          >
            <div className="icon icon-invert-color"></div>
          </div>
        </li>

        {config.confCrm && (
          <Route path="/crm">
            <li>
              <Link
                to="/driver"
                title={m("driver")}
                className={dark ? "" : "gx-icon-white"}
                onClick={() => dispatch(changeModePath("/driver"))}
              >
                <CarOutlined/>
              </Link>
            </li>
          </Route>
        )}

        {config.confDriver && (
          <Route path="/driver">
            {user.interface === "driver" ? (<></>) : (<li>
              <Link
                className={dark ? "" : "gx-icon-white"}
                to="/crm"
                title={m("crm")}
                onClick={() => dispatch(changeModePath("/crm"))}
              >
                <DatabaseOutlined/>
              </Link>
            </li>)}
          </Route>
        )}

        {user.isAdmin && config.confAdmin && (
          <li>
            <Link className={dark ? "" : "gx-icon-white"} to="/admin/" title={m("admin")}>
              <ToolOutlined/>
            </Link>
          </li>
        )}
      </Flex>
    </ul>
  );
};

export default AppsNavigation;
