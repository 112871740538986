const languageData = [
  {
    languageId: "english",
    locale: "en",
    name: "English",
    icon: "us",
  },
  {
    languageId: "german",
    locale: "de",
    name: "Deutschland",
    icon: "de",
  },
  {
    languageId: "french",
    locale: "fr",
    name: "Français",
    icon: "fr",
  },
  {
    languageId: "italian",
    locale: "it",
    name: "Italiano",
    icon: "it",
  },
  {
    languageId: "dutch",
    locale: "nl",
    name: "Nederlands",
    icon: "nl",
  }
];

export default languageData;
