import React from "react";
import { useSelector } from "react-redux";
import { Avatar, Popover } from "antd";
import { injectIntl } from "react-intl";
import UserMenu from "../../components/UserInfo/UserMenu";

const UserProfile = (props) => {
  const user = useSelector(({ user }) => user);
  const show = user.show || {};
  const { confAuthorize } = window.appConfig;

  if (!confAuthorize) return null;

  return (
    <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
      <Popover placement="bottomRight" content={<UserMenu />} trigger="click">
        {show.picture && (
          <Avatar
            src={show.picture}
            className="gx-size-40 gx-pointer gx-mr-3"
            alt=""
          />
        )}
        {!show.picture && (
          <Avatar className="gx-size-40 gx-pointer gx-mr-3">
            {show.literal}
          </Avatar>
        )}
        <span className="gx-avatar-name">
          {show.hello}
          <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />
        </span>
      </Popover>
    </div>
  );
};

export default injectIntl(UserProfile);
