import React from "react";
import { Menu } from "antd";
import { Link, useRouteMatch } from "react-router-dom";
import IntlMessages from "../../util/IntlMessages";
import { horizontalMenu } from "../../routes/menu";

const HorizontalNav = () => {
  let match = useRouteMatch();
  let menu = horizontalMenu[match.path] || {};
  return (
    <Menu mode="horizontal">
      {Object.entries(menu).map(([groupKey, groupItems]) => {
        return (
          <Menu.SubMenu
            key={groupKey}
            className="gx-menu-horizontal gx-submenu-popup-curve gx-inside-submenu-popup-curve"
            title={<IntlMessages id={groupKey} />}
          >
            {Object.entries(groupItems).map(([itemKey, item]) => {
              return (
                <Menu.Item key={itemKey}>
                  <Link to={item.url}>
                    {item.icon}
                    <IntlMessages id={item.label} />
                  </Link>
                </Menu.Item>
              );
            })}
          </Menu.SubMenu>
        );
      })}
    </Menu>
  );
};

HorizontalNav.propTypes = {};

export default HorizontalNav;
