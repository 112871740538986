import config from "./config";
import accessToken from "./token";
import getChildren from "../util/getChildren";

import NProgress from "nprogress";
import "nprogress/nprogress.css";
import { message } from "antd";
import md5 from "md5";
import { isEmpty } from "lodash";

const rest = {
  cache: async function () {
    let key = md5(JSON.stringify(arguments));
    if (!rest[key]) {
      rest[key] = await rest.q(...arguments);
    }
    return rest[key];
  },
  q: async function (
    path,
    rawBody = {},
    normalizeParams = false,
    forceToken = false,
    skipToken = false,
    forceFullPath = false
  ) {
    const token = forceToken ? forceToken : accessToken.get();
    if (!token && !skipToken) {
      if (path === "/whoami") {
        return false;
      }
      return rest.error("No token present");
    }
    if (!path) {
      return rest.error("No path specified");
    }

    //Prepare request
    if (!NProgress.isStarted() && !normalizeParams.silent) {
      NProgress.start();
    }
    const body = JSON.stringify(rawBody);
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    if (!skipToken) {
      headers.append("Authorization", `Bearer ${token}`);
    }

    //Make request
    let apiPath = path.includes("oauth") ? "" : config.apiPath;
    const url = forceFullPath || `${config.apiDomain}${apiPath}${path}`;
    if (config.debug) {
      console.log("--> API ", path, body, headers);
    }

    let json = null;
    let response = null;
    try {
      response = await fetch(url, {
        method: "POST",
        headers,
        body,
        mode: "cors",
        cache: "no-store",
      });
      if (response) {
        json = await response.json();
      }
    } catch (e) {
      return rest.error(e.message);
    }

    if (config.debug) console.log(`API <-- ${path}`, json);
    NProgress.done();

    if (json) {
      const normalize = normalizeParams || {};

      //Handle errors
      let errMessage = "";
      if (json.hasOwnProperty("error"))
        errMessage = json.error["message"] || "";

      //More logig should perform here
      if (response.status === 403) {
        errMessage = `403 ${errMessage}`;
      }
      if (response.status === 401) {
        errMessage = `401 ${errMessage}`;
      }
      if (response.status === 400) {
        errMessage = `400 ${errMessage}`;
      }

      if (json.hasOwnProperty("error")) {
        rest.error(errMessage);
        if (normalize.toObject || normalize.toTree) return {};
        if (normalize.toArray) return [];
        if (normalize.exact) return json;
        return false;
      }

      //Normalize & return result
      if (!normalizeParams) {
        return json; //No normaliztion rqrd
      }

      if (isEmpty(json)) {
        if (normalize.toObject || normalize.toTree) return {};
        if (normalize.toArray) return [];
      }

      //To array...
      let items = Array.isArray(json) ? json : [json];

      //Sort...
      if (normalize.sortBy) {
        items.sort((a, b) => {
          return a[normalize.sortBy] - b[normalize.sortBy];
        });
      }

      if (normalize.toArray) {
        return items;
      }

      if (normalize.toObject) {
        let result = {};
        const keyField = normalize.keyField || "id";
        items.forEach((item, index) => {
          let key = item[keyField] || index;
          result[key] = item;
        });
        return result;
      }

      if (normalize.toTree) {
        let result = [];
        let itemsIDs = [];

        if (normalize.defaultTreeItems) {
          items = items.concat(normalize.defaultTreeItems);
        }

        items.forEach((item) => {
          itemsIDs.push(item.id);
        });

        const parentKey = normalize.parentKey || "parent";
        items.forEach((item) => {
          if (
            !item[parentKey] ||
            item.level === 0 ||
            !itemsIDs.includes(item[parentKey])
          ) {
            result.push({
              ...item,
              level: 0,
              title: item.label ?? item.name ?? item.key,
              value: item.id,
              key: item.id,
              children: getChildren(items, item.id, 1, parentKey),
            });
          }
        });
        return result;
      }

      return json;
    } else {
      //Unknown error
      return rest.error(`API Server did not respond correctly`);
    }
  },

  error: (text) => {
    NProgress.done();
    if (config.debug) {
      if (process.env.NODE_ENV !== "production") {
        message.warning(`API: ${text}`);
      }
      console.log(`%c API ERROR: ${text}`, "color: orangered");
    }
    return false;
  },

  log: (message) => {
    if (config.debug) console.log(`%c API: ${message}`, "color: blue");
  },
};

export default rest;
