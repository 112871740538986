import React from "react";
export default function Flex(props) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: props.column ? "column" : "row",
        alignItems: props.alignItems || "center",
        justifyContent: props.between ? "space-between" : "start",
        flexWrap: props.wrap ? "wrap" : "nowrap",
      }}
      className={props.className}
    >
      {props.children}
    </div>
  );
}
