import React from "react";
import { Button, Form, Input, Alert } from "antd";
import { Link } from "react-router-dom";
import { injectIntl } from "react-intl";
import { Helmet } from "react-helmet";
import CircularProgress from "../components/CircularProgress";
import Context from "../Context";
import credentialToken from "./../api/credentials";

class SignRecovery extends React.Component {
  static contextType = Context;
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      proceedSuccess: false,
      resetSuccess: false,
      pending: false,
    };
  }
  m = (key) => {
    const { intl } = this.props;
    return intl.formatMessage({ id: "recovery." + key });
  };
  proceed = async (data) => {
    const { ticket, answer } = this.props.match.params;

    this.setState({ pending: true, error: false, proceedSuccess: false });

    if (data.email) {
      let result = await this.context.api.q(
        "/user/password/recovery",
        {
          identifier: data.email,
        },
        { exact: true },
        credentialToken.getAccess()
      );

      if (result.ticket) {
        this.setState({ proceedSuccess: true });
      } else {
        if (result.hasOwnProperty("error")) {
          this.setState({ error: result.error.message });
        }
      }
    }

    if (data.password) {
      let result = await this.context.api.q(
        "/user/password/reset",
        {
          ticket: ticket,
          securityanswer: answer,
          password: data.password,
        },
        { exact: true },
        credentialToken.getAccess()
      );

      if (result?.result) {
        this.setState({ resetSuccess: true });
      } else {
        if (result?.message) {
          this.setState({ error: result.message });
        } else {
          this.setState({
            error: this.props.intl.formatMessage({ id: "unknown.error" }),
          });
        }
      }
    }

    this.setState({ pending: false });
  };

  render() {
    const { ticket, answer } = this.props.match.params;
    const { pending, error, proceedSuccess, resetSuccess } = this.state;

    let reset = ticket && answer ? true : false;
    let success = proceedSuccess || resetSuccess ? true : false;
    return (
      <div className="gx-app-login-wrap">
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg">
                <img src={"/assets/images/sbg.png"} alt="OCPP CS" />
              </div>
              <div className="gx-app-logo-wid">
                <Helmet>
                  <title>{this.m("recovery")}</title>
                </Helmet>
                <h1>{this.m("recovery")}</h1>
                <p>{this.m("text")}</p>
              </div>
              <div className="gx-app-logo">
                <img alt="example" src="/assets/images/logo.png" />
              </div>
            </div>
            <div className="gx-app-login-content">
              {proceedSuccess && (
                <div>
                  <Alert message={this.m("proceedSuccess")} type="success" />
                </div>
              )}
              {resetSuccess && (
                <div>
                  <Alert message={this.m("resetSuccess")} type="success" />
                  <Link to="/signin">{this.m("signin")}</Link>
                </div>
              )}
              {!success && (
                <Form
                  name="basic"
                  onFinish={this.proceed}
                  className="gx-signin-form gx-form-row0"
                >
                  {!reset && (
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          type: "email",
                          message: this.m("email.invalid"),
                        },
                      ]}
                    >
                      <Input placeholder={this.m("email")} />
                    </Form.Item>
                  )}

                  {reset && (
                    <Form.Item
                      name="password"
                      rules={[
                        { required: true, message: this.m("password.invalid") },
                      ]}
                    >
                      <Input type="password" placeholder={this.m("password")} />
                    </Form.Item>
                  )}

                  {error && <Alert message={error} type="error" />}
                  <Form.Item>
                    <Button
                      type="primary"
                      className="gx-mb-0"
                      htmlType="submit"
                    >
                      {this.m("proceed")}
                    </Button>
                    <span>{this.m("or")}</span>{" "}
                    <Link to="/signin">{this.m("signin")}</Link>
                  </Form.Item>
                </Form>
              )}
            </div>
            {pending && (
              <div className="gx-loader-view">
                <CircularProgress />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(SignRecovery);
