import React, { Suspense, useEffect } from "react";
import { Layout } from "antd";
import { useDispatch } from "react-redux";
import InsideHeader from "../Topbar/InsideHeader/index";
import { Helmet } from "react-helmet";
import Topbar from "../Topbar/index";
import Routes from "../../routes/Routes";
import CircularProgress from "../../components/CircularProgress";

import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_FIXED,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
} from "../../constants/ThemeSetting";
import { useRouteMatch } from "react-router-dom";
import { updateWindowWidth } from "../../appRedux/actions";
import SidebarContainer from "./SidebarContainer";
import ErrorBoundary from "../../util/ErrorBoundary";
import Offline from "../Error/Offline";

const { Content, Footer } = Layout;

const getContainerClass = (navStyle) => {
  switch (navStyle) {
    case NAV_STYLE_DARK_HORIZONTAL:
      return "gx-container-wrap";
    case NAV_STYLE_DEFAULT_HORIZONTAL:
      return "gx-container-wrap";
    case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
      return "gx-container-wrap";
    case NAV_STYLE_BELOW_HEADER:
      return "gx-container-wrap";
    case NAV_STYLE_ABOVE_HEADER:
      return "gx-container-wrap";
    default:
      return "";
  }
};

const getNavStyles = (navStyle) => {
  switch (navStyle) {
    case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
      return <InsideHeader />;
    case NAV_STYLE_FIXED:
      return <Topbar />;

    default:
      return null;
  }
};

const LayoutContainer = (props) => {
  const config = window.appConfig || {};

  let navStyle = props.wide
    ? "NAV_STYLE_INSIDE_HEADER_HORIZONTAL"
    : "NAV_STYLE_FIXED";

  const match = useRouteMatch();
  const dispatch = useDispatch();

  useEffect(() => {
    window.addEventListener("resize", () => {
      dispatch(updateWindowWidth(window.innerWidth));
    });
  }, [dispatch]);

  return (
    <ErrorBoundary>
      <Layout className="gx-app-layout">
        <Offline />
        <Helmet
          defaultTitle="OCPP CS"
          titleTemplate={`%s ${config.creditsShortText && " - "} ${
            config.creditsShortText || ""
          }`}
        />
        <SidebarContainer navStyle={navStyle} />
        <Layout>
          {getNavStyles(navStyle)}
          <Content
            className={`gx-layout-content ${getContainerClass(navStyle)} `}
          >
            <Suspense fallback={<CircularProgress />}>
              <Routes match={match} />
            </Suspense>

            <Footer>
              <div className="gx-layout-footer-content">
                {config.creditsText}
              </div>
            </Footer>
          </Content>
        </Layout>
      </Layout>
    </ErrorBoundary>
  );
};
export default LayoutContainer;
