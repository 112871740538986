import React from "react";
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: false };
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  render() {
    const { error } = this.state;
    if (this.state.error) {
      return (
        <div className="gx-page-error-container">
          <div className="gx-page-error-content">
            <div className="gx-error-code">Whoops</div>
            <h2>{error.toString()}</h2>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
