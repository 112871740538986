import React from "react";
import { Button, Form, Input, Alert } from "antd";
import { Link } from "react-router-dom";
import { injectIntl } from "react-intl";
import { Helmet } from "react-helmet";
import CircularProgress from "../components/CircularProgress";
import Context from "../Context";
import credentialToken from "./../api/credentials";

class SignConfirm extends React.Component {
  static contextType = Context;
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      success: false,
      pending: false,
    };
  }
  m = (key) => {
    const { intl } = this.props;
    return intl.formatMessage({ id: "confirm." + key });
  };
  proceed = async (data) => {
    const { code } = this.props.match.params;

    this.setState({ pending: true, error: false, success: false });

    let result = await this.context.api.q(
      "/verification/email/confirm",
      {
        code: code,
      },
      { exact: true },
      credentialToken.getAccess()
    );

    if (result.result) {
      this.setState({ success: true });
    } else {
      this.setState({ error: result.message });
    }

    this.setState({ pending: false });
  };

  render() {
    const { code } = this.props.match.params;
    const { pending, error, success } = this.state;

    return (
      <div className="gx-app-login-wrap">
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg">
                <img src={"/assets/images/sbg.png"} alt="OCPP CS" />
              </div>
              <div className="gx-app-logo-wid">
                <Helmet>
                  <title>{this.m("confirm")}</title>
                </Helmet>
                <h1>{this.m("confirm")}</h1>
                <p>{this.m("text")}</p>
              </div>
              <div className="gx-app-logo">
                <img alt="example" src="/assets/images/logo.png" />
              </div>
            </div>
            <div className="gx-app-login-content">
              {success && (
                <div>
                  <Alert message={this.m("success")} type="success" />
                  {this.m("nowucan")}{" "}
                  <Link to="/signin">{this.m("signin")}</Link>
                </div>
              )}

              {!success && (
                <Form
                  name="basic"
                  onFinish={this.proceed}
                  className="gx-signin-form gx-form-row0"
                >
                  <Form.Item
                    name="code"
                    initialValue={code}
                    rules={[
                      {
                        required: true,
                        message: this.m("code.invalid"),
                      },
                    ]}
                  >
                    <Input placeholder={this.m("code")} />
                  </Form.Item>

                  {error && <Alert message={error} type="error" />}
                  <Form.Item>
                    <Button
                      type="primary"
                      className="gx-mb-0"
                      htmlType="submit"
                    >
                      {this.m("proceed")}
                    </Button>
                  </Form.Item>
                </Form>
              )}
            </div>
            {pending && (
              <div className="gx-loader-view">
                <CircularProgress />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(SignConfirm);
