import antdNL from "antd/lib/locale-provider/nl_NL";
import nlMessages from "../locales/nl_NL.json";

const NlLang = {
  messages: {
    ...nlMessages
  },
  antd: antdNL,
  locale: 'nl-NL',
};
export default NlLang;
