import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import CircularProgress from "../../components/CircularProgress";
import IntlMessages from "../../util/IntlMessages";
export default function Offline() {
  let [visible, changeVisible] = useState(false);

  const goOnline = () => {
    changeVisible(false);
  };

  const goOffline = () => {
    changeVisible(true);
  };

  useEffect(() => {
    window.addEventListener("onlineGoOnline", goOnline);
    window.addEventListener("onlineGoOffline", goOffline);
    return function () {
      window.removeEventListener("onlineGoOnline", goOnline);
      window.removeEventListener("onlineGoOffline", goOffline);
    };
  });

  return (
    <Modal
      visible={visible}
      title={null}
      footer={null}
      closable={false}
      centered
    >
      <CircularProgress className={"small gx-mt-2"} />
      <h3 className="gx-text-center gx-mt-3 gx-text-primary">
        <IntlMessages id="offline" />
      </h3>
    </Modal>
  );
}
