import CancelReservation from "./CancelReservation.json";
import ChangeAvailability from "./ChangeAvailability.json";
import ChangeConfiguration from "./ChangeConfiguration.json";
import ClearCache from "./ClearCache.json";
import ClearChargingProfile from "./ClearChargingProfile.json";
import DataTransfer from "./DataTransfer.json";
import GetCompositeSchedule from "./GetCompositeSchedule.json";
import GetConfiguration from "./GetConfiguration.json";
import GetDiagnostics from "./GetDiagnostics.json";
import GetLocalListVersion from "./GetLocalListVersion.json";
import RemoteStartTransaction from "./RemoteStartTransaction.json";
import RemoteStopTransaction from "./RemoteStopTransaction.json";
import ReserveNow from "./ReserveNow.json";
import Reset from "./Reset.json";
import SendLocalList from "./SendLocalList.json";
import SetChargingProfile from "./SetChargingProfile.json";
import TriggerMessage from "./TriggerMessage.json";
import UnlockConnector from "./UnlockConnector.json";
import UpdateFirmware from "./UpdateFirmware.json";

const commands = {
  CancelReservation: {
    form: CancelReservation,
  },
  ChangeAvailability: {
    form: ChangeAvailability,
  },
  ChangeConfiguration: {
    form: ChangeConfiguration,
  },
  ClearCache: {
    form: ClearCache,
  },
  ClearChargingProfile: {
    form: ClearChargingProfile,
  },
  DataTransfer: {
    form: DataTransfer,
  },
  GetCompositeSchedule: {
    form: GetCompositeSchedule,
  },
  GetConfiguration: {
    form: GetConfiguration,
  },
  GetDiagnostics: {
    form: GetDiagnostics,
  },
  GetLocalListVersion: {
    form: GetLocalListVersion,
  },
  RemoteStartTransaction: {
    form: RemoteStartTransaction,
  },
  RemoteStopTransaction: {
    form: RemoteStopTransaction,
  },
  ReserveNow: {
    form: ReserveNow,
  },
  Reset: {
    form: Reset,
  },
  SendLocalList: {
    form: SendLocalList,
  },
  SetChargingProfile: {
    form: SetChargingProfile,
  },
  TriggerMessage: {
    form: TriggerMessage,
  },
  UnlockConnector: {
    form: UnlockConnector,
  },
  UpdateFirmware: {
    form: UpdateFirmware,
  },
};
export default commands;
