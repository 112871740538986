import React from "react";
import { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import Fatal from "../containers/Error/Fatal";

const routes = [
  //driver
  {
    path: "/driver/stations",
    component: lazy(() => import("./Stations/DriverStations")),
    exact: false,
    params: true,
  },
  {
    path: "/driver/cards",
    component: lazy(() => import("./Card/Card")),
    exact: false,
    params: false,
  },
  {
    path: "/driver/cars",
    component: lazy(() => import("./Car/Car")),
    exact: false,
    params: false,
  },
  {
    path: "/driver",
    component: lazy(() => import("./Stations/DriverStations")),
    exact: true,
    params: false,
  },
  {
    path: "/driver/notice",
    component: lazy(() => import("./Notice/Notice")),
    exact: false,
    params: false,
  },
  //crm
  {
    path: "/crm/station",
    component: lazy(() => import("./Stations/StationDetail")),
    exact: false,
    params: true,
  },
  {
    path: "/crm/stations",
    component: lazy(() => import("./Stations/Stations")),
    exact: true,
    params: false,
  },
  {
    path: "/crm/transactions",
    component: lazy(() => import("./Transactions/Transactions")),
    exact: true,
    params: false,
  },
  {
    path: "/crm/cards",
    component: lazy(() => import("./Card/Card")),
    exact: false,
    params: false,
  },
  {
    path: "/crm/profile",
    component: lazy(() => import("./Profile/Profile")),
    exact: false,
    params: false,
  },
  {
    path: "/crm/search",
    component: lazy(() => import("./Search/Search")),
    exact: false,
    params: false,
  },
  {
    path: "/crm/invoice",
    component: lazy(() => import("./Invoice/Invoice")),
    exact: false,
    params: false,
  },
  {
    path: "/crm/notice",
    component: lazy(() => import("./Notice/Notice")),
    exact: false,
    params: false,
  },
  {
    path: "/crm",
    component: lazy(() => import("./Stations/Stations")),
    exact: true,
    params: false,
  },
  {
    path: "/crm/client",
    component: lazy(() => import("./Clients/Clients")),
    exact: true,
    params: false,
  },
  {
    path: "/crm/clients",
    component: lazy(() => import("./Clients/ClientDetail")),
    exact: false,
    params: true,
  },
  {
    path: "/crm/account",
    component: lazy(() => import("./Accounts/Accounts")),
    exact: true,
    params: false,
  },
  {
    path: "/crm/network",
    component: lazy(() => import("./Networks/Networks")),
    exact: true,
    params: false,
  },
  {
    path: "/crm/tariff",
    component: lazy(() => import("./Tariffs/Tariffs")),
    exact: true,
    params: false,
  },

  //ocpp
  {
    path: "/ocpp/command",
    component: lazy(() => import("./Ocpp/OcppCommands/OcppCommands")),
    exact: false,
    params: true,
  },
  {
    path: "/ocpp",
    component: lazy(() => import("./Ocpp/OcppStations/OcppStations")),
    exact: true,
    params: false,
  },

  //admin
  {
    path: "/admin/groups",
    component: lazy(() => import("./Admin/Groups/Groups")),
    exact: false,
    params: true,
  },
  {
    path: "/admin/users",
    component: lazy(() => import("./Admin/Users/Users")),
    exact: false,
    params: true,
  },
  {
    path: "/admin/registry",
    component: lazy(() => import("./Admin/Registry/Registry")),
    exact: false,
    params: true,
  },
  {
    path: "/admin/console",
    component: lazy(() => import("./Admin/Console/Console")),
    exact: false,
    params: true,
  },
  {
    path: "/admin/resource",
    component: lazy(() => import("./Admin/Resource/Resource")),
    exact: false,
    params: true,
  },
  {
    path: "/admin/workflow",
    component: lazy(() => import("./Admin/Workflow/Workflow")),
    exact: false,
    params: true,
  },
  {
    path: "/admin/reference",
    component: lazy(() => import("./Admin/Reference/Reference")),
    exact: false,
    params: true,
  },
  {
    path: "/admin/dblog",
    component: lazy(() => import("./Admin/DbLog/DbLog")),
    exact: false,
    params: false,
  },
  {
    path: "/admin/apilog",
    component: lazy(() => import("./Admin/ApiLog/ApiLog")),
    exact: false,
    params: false,
  },
  {
    path: "/admin",
    component: lazy(() => import("./Admin/Dashboard/AdminDashboard")),
    exact: true,
    params: false,
  },
];

const Routes = () => (
  <div className="gx-main-content-wrapper">
    <Switch>
      {routes.map((route, index) => (
        <Route
          key={index}
          exact={route.exact}
          path={
            route.params
              ? [
                  `${route.path}/:param1/:param2/:param3`,
                  `${route.path}/:param1/:param2`,
                  `${route.path}/:param1`,
                  `${route.path}`,
                ]
              : `${route.path}`
          }
        >
          <route.component />
        </Route>
      ))}

      <Route path="*">
        <Fatal code={404} />
      </Route>
    </Switch>
  </div>
);

export default Routes;
